// most of this code is copied from the internet
export default class CanvasDragZoom {

    #canvas_element;
    #context;
    #scale_factor;
    #last_x;
    #last_y;
    #drag_start;
    #on_change_callback;
    #on_mouse_up_callback;
    #is_left_mouse_button_down;

    #documentBodyStyleUserSelect;
    
    constructor(canvas_element, context, on_change_callback, on_mouse_up_callback) {
        this.#canvas_element = canvas_element;
        this.#context = context;
        this.#on_change_callback = on_change_callback;
        this.#on_mouse_up_callback = on_mouse_up_callback;
        this.#scale_factor = 1.1;
        this.#last_x = canvas_element.width / 2;
        this.#last_y = canvas_element.height / 2;
        this.#is_left_mouse_button_down = false;

        this.#trackTransforms();

        canvas_element.addEventListener('mousedown', ((event) => this.#mouse_down(event)), false);
        canvas_element.addEventListener('mousemove', ((event) => this.#mouse_move(event)), false);
        canvas_element.addEventListener('mouseup', ((event) => this.#mouse_up(event)), false);
        canvas_element.addEventListener('mouseout', ((event) => this.#mouse_out(event)), false);

        canvas_element.addEventListener('DOMMouseScroll', ((event) => this.#handleScroll(event)), false);
        canvas_element.addEventListener('mousewheel', ((event) => this.#handleScroll(event)), false);
    }

    #handleScroll(event) {
        let delta = event.wheelDelta ? event.wheelDelta / 40 : event.detail ? -event.detail : 0;
        if (delta) this.#zoom(delta);
        return event.preventDefault() && false;
    }

    #zoom(clicks) {
        let pt = this.#context.transformedPoint(this.#last_x, this.#last_y);
        this.#context.translate(pt.x, pt.y);
        let factor = Math.pow(this.#scale_factor, clicks);
        this.#context.scale(factor, factor);
        this.#context.translate(-pt.x, -pt.y);
        this.#on_change_callback();
    }

    #mouse_down(event) {
        if (event.button != 0) {
            return;
        }
        this.#is_left_mouse_button_down = true;

        const x = event.offsetX || (event.pageX - this.#canvas_element.offsetLeft);
        const y = event.offsetY || (event.pageY - this.#canvas_element.offsetTop);

        this.#documentBodyStyleUserSelect = document.body.style.userSelect;
        document.body.style.userSelect = 'none';

        this.#last_x = x;
        this.#last_y = y;
        this.#drag_start = this.#context.transformedPoint(this.#last_x, this.#last_y);
    }

    #mouse_move(event) {
        this.#last_x = event.offsetX || (event.pageX - this.#canvas_element.offsetLeft);
        this.#last_y = event.offsetY || (event.pageY - this.#canvas_element.offsetTop);

        if (!this.#is_left_mouse_button_down) {
            return;
        }

        let pt = this.#context.transformedPoint(this.#last_x, this.#last_y);
        this.#context.translate(pt.x - this.#drag_start.x, pt.y - this.#drag_start.y);
        this.#on_change_callback();
    }

    #mouse_up(event) {
        if (event.button != 0 || !this.#is_left_mouse_button_down) {
            return;
        }
        this.#is_left_mouse_button_down = false;

        const x = event.offsetX || (event.pageX - this.#canvas_element.offsetLeft);
        const y = event.offsetY || (event.pageY - this.#canvas_element.offsetTop);
        this.#on_mouse_up_callback(this.#context.transformedPoint(x, y));

        this.#drag_start = null;

        document.body.style.userSelect = this.#documentBodyStyleUserSelect;
        //if (!historythis.#is_dragging) zoom(evt.shiftKey ? -1 : 1 );
    }

    #mouse_out() {
        this.#is_left_mouse_button_down = false;
        this.#drag_start = null;

        document.body.style.userSelect = this.#documentBodyStyleUserSelect;
    }

    // Adds ctx.getTransform() - returns an SVGMatrix
    // Adds ctx.transformedPoint(x,y) - returns an SVGPoint
    #trackTransforms() {
        let svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
        let xform = svg.createSVGMatrix();
        let context = this.#context;
        this.#context.getTransform = function () { return xform; };

        let savedTransforms = [];
        let save = this.#context.save;
        this.#context.save = function () {
            savedTransforms.push(xform.translate(0, 0));
            return save.call(context);
        };
        let restore = this.#context.restore;
        this.#context.restore = function () {
            xform = savedTransforms.pop();
            return restore.call(context);
        };

        let scale = this.#context.scale;
        this.#context.scale = function (sx, sy) {
            xform = xform.scaleNonUniform(sx, sy);
            return scale.call(context, sx, sy);
        };
        let rotate = this.#context.rotate;
        this.#context.rotate = function (radians) {
            xform = xform.rotate(radians * 180 / Math.PI);
            return rotate.call(context, radians);
        };
        let translate = this.#context.translate;
        this.#context.translate = function (dx, dy) {
            xform = xform.translate(dx, dy);
            return translate.call(context, dx, dy);
        };
        let transform = this.#context.transform;
        this.#context.transform = function (a, b, c, d, e, f) {
            let m2 = svg.createSVGMatrix();
            m2.a = a; m2.b = b; m2.c = c; m2.d = d; m2.e = e; m2.f = f;
            xform = xform.multiply(m2);
            return transform.call(context, a, b, c, d, e, f);
        };
        let setTransform = this.#context.setTransform;
        this.#context.setTransform = function (a, b, c, d, e, f) {
            xform.a = a;
            xform.b = b;
            xform.c = c;
            xform.d = d;
            xform.e = e;
            xform.f = f;
            return setTransform.call(context, a, b, c, d, e, f);
        };
        let pt = svg.createSVGPoint();
        this.#context.transformedPoint = function (x, y) {
            pt.x = x; pt.y = y;
            return pt.matrixTransform(xform.inverse());
        }
    }
}
