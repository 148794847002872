<template>
    <div>
        <canvas ref="canvas" @contextmenu.prevent="resetTransformationMatrix" />
    </div>
</template>

<script>
import CanvasDragZoom from '../canvas_drag_zoom.js'

export default {
    name: 'BDMPartImage',
    props: ['imageUrl', 'callouts'],
    data() {
        return {
            canvas: undefined,
            context: undefined,
            backgroundImage: undefined,
            scale: 1.0,
            circleSize: 15.0
        };
    },
    mounted() {
        let thiz = this;
        this.context = this.$refs.canvas.getContext("2d");
        this.canvas = new CanvasDragZoom(this.$refs.canvas, this.context, () => { thiz.redraw(); }, (position) => {this.canvasClicked(position);});

        this.$refs.canvas.style.width='100%';
        this.$refs.canvas.style.height='100%';
        this.$refs.canvas.width  = this.$refs.canvas.offsetWidth;
        this.$refs.canvas.height = this.$refs.canvas.offsetHeight;

        let img = new Image;
        img.onload = function () {
            thiz.backgroundImage = img;
            thiz.context.save();
            if (thiz.$refs.canvas) {
                thiz.resetTransformationMatrix();
                thiz.redraw();
            }
        };
        img.src = this.imageUrl;

        window.addEventListener("resize", this.resized);
    },
    unmounted() {
        window.removeEventListener("resize", this.resized);
    },
    methods: {
        async resized() {
            await this.$nextTick();
            this.$refs.canvas.style.width='100%';
            this.$refs.canvas.style.height='100%';
            this.$refs.canvas.width  = this.$refs.canvas.offsetWidth;
            this.$refs.canvas.height = this.$refs.canvas.offsetHeight;
            this.resetTransformationMatrix();
        },
        updateImageScale() {
            const scaleX = this.$refs.canvas.width / this.backgroundImage.width;
            const scaleY = this.$refs.canvas.height / this.backgroundImage.height;
            this.scale = Math.min(scaleX, scaleY);
        },
        resetTransformationMatrix() {
            this.context.restore();
            this.context.save();
            this.updateImageScale();
            this.context.scale(this.scale, this.scale);
            this.redraw();
        },
        redraw() {
            this.context.fillStyle = "black";
            let p1 = this.context.transformedPoint(0, 0);
            let p2 = this.context.transformedPoint(this.$refs.canvas.width, this.$refs.canvas.height);
            this.context.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);

            if (this.backgroundImage) {
                this.context.drawImage(this.backgroundImage, 0, 0, this.backgroundImage.width, this.backgroundImage.height);
            }
            
            this.context.fillStyle = "rgba(255.0, 0, 0, 0.3)";
            for (const callout of this.callouts) {
                for (const point of callout["points"]) {
                    this.context.beginPath();
                    this.context.arc(point["x"], point["y"], this.circleSize, 0, Math.PI * 2);
                    this.context.fill();
                    this.context.stroke();
                }
            }
        },
        canvasClicked(event) {
            for (const callout of this.callouts) {
                for (const point of callout["points"]) {
                    const distanceSquared = (point["x"] - event.x) * (point["x"] - event.x) + (point["y"] - event.y) * (point["y"] - event.y);
                    if (distanceSquared < this.circleSize * this.circleSize) {
                        this.$emit("calloutClicked", callout["label"]);
                        return;
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
</style>
