<template>
	<div>
		<BDMNavbar :itemCount="0" />
		<br />
		<br />
		<br />
		<h1>{{ tractorName }} (id: {{ tractorId }})</h1>
		<br />
		<div class="category-list-container">
			<BDMCategoryCard v-for="category in categories" :key="category" :name="category['name']"
				:parts="category['parts']" :url="getCategoryUrl(category)" />
		</div>
	</div>
</template>

<script>
import BDMNavbar from '../components/BDMNavbar.vue'
import BDMCategoryCard from '../components/BDMCategoryCard.vue'

export default {
	name: 'CategoriesView',
	components: {
		BDMNavbar,
		BDMCategoryCard
	},
	data() {
		return {
			tractorId: undefined,
			tractorName: "",
			tractorDataJson: undefined,
			categories: []
		}
	},
	mounted() {
		this.tractorId = this.$route.params.tractorId;
		this.getTractorJsonData();
	},
	methods: {
		getTractorJsonData() {
			const url = "/api/tractors/tractor_" + this.tractorId + ".json";
			fetch(url)
				.then((response) => {
					if (response.ok) {
						return response.json();
					}
				})
				.then((responseJson) => {
					this.tractorDataJson = responseJson;
					this.tractorName = responseJson['name'];
					this.categories = this.tractorDataJson['categories'];
				})
				.catch((e) => {
					console.log(url + " error: " + e);
				});
		},
		getCategoryUrl(category) {
			return "/tractors/" + this.tractorId + "/categories/" + category['category_id'];
		}
	}
}
</script>

<style scoped>
.category-list-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
</style>
