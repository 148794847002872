<template>
    <div>
        <div class="card" @click="$router.push(url)">
            <img class="tractor-image" :src="imageUrl" alt="Tractor image" />
            <div class="container">
                <h4><b>{{ name }}</b></h4>
                <p>{{ description }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BDMTractorCard',
    props: ['name', 'description', 'url', 'imageUrl']
}
</script>

<style scoped>
.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 40%;
    border-radius: 5px;
    width: 300px;
    height: 500px;
    cursor: pointer;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.tractor-image {
    width: 100%;
}
</style>
