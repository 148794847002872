<template>
    <div class="main-container" ref="mainContainer">
        <BDMNavbar :itemCount="numberOfParts" />
        <!-- TODO: use padding/margin instead of brs -->
        <br />
        <br />
        <br />
        <br />
        <h1>{{ tractorName }} (id: {{ tractorId }})</h1>
        <h3>Search results for '{{ searchPattern }}'</h3>
        <BDMPartList :parts="filteredParts" :showGoToCategoryButton="true" />
        <br />
    </div>
</template>

<script>
import BDMNavbar from '../components/BDMNavbar.vue'
import BDMPartList from '../components/BDMPartList.vue';

export default {
    name: 'SearchResultsView',
    data() {
        return {
            allParts: [],
            tractorId: undefined,
            tractorName: "",
            searchPattern: ""
        };
    },
    components: {
        BDMNavbar,
        BDMPartList
    },
    mounted() {
        this.searchPattern = this.$route.query.q;

        // retrieve parts
        const url = "/api/tractors/tractor_" + this.$route.params.tractorId + ".json";
        fetch(url)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((responseJson) => {
                this.tractorId = responseJson['tractor_id'];
                this.tractorName = responseJson['name'];
                let parts = [];
                for (const category of responseJson['categories']) {
                    for (const part of category['parts']) {
                        let partt = part;
                        partt["category_id"] = category["category_id"];
                        parts.push(partt);
                    }
                }
                this.allParts = parts;
            })
            .catch((e) => {
                console.log("error: " + e);
            });
    },
    computed: {
        numberOfParts() {
            return this.filteredParts.length;
        },
        filteredParts() {
            let result = [];
            for (const part of this.allParts) {
                if (isPartContainText(part, this.searchPattern.toLowerCase())) {
                    result.push(part);
                }
            }
            return result;
        }
    },
    watch: {
        "$route.query.q"() {
            this.searchPattern = this.$route.query.q;
        }
    }
}

function isPartContainText(part, searchPattern) {
    if (part["name"].toLowerCase().indexOf(searchPattern) != -1 || part["code"].toLowerCase().indexOf(searchPattern) != -1) {
        return true;
    }

    for (const item of part["items"]) {
        if (item["name"].toLowerCase().indexOf(searchPattern) != -1 || item["partNumber"].toLowerCase().indexOf(searchPattern) != -1) {
            return true;
        }
    }

    return false;
}
</script>

<style>
.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.parts-container {
    width: 90%;
}
</style>
