<template>
	<div>
		<div class="part-container">
			<div class="part-image-container" ref="imageContainer">
				<BDMPartImage :imageUrl="getImageSrc()" :callouts="getCallouts()" @calloutClicked="calloutClicked" />
			</div>
			<div class="part-info-container">
				<BDMPartInfo ref="partInfo" :id="part['id']" :name="part['name']" :code="part['code']"
					:url="part['url']" :items="part['items']" :partUrl="partUrl"
					:showGoToCategoryButton="showGoToCategoryButton" />
			</div>
		</div>
	</div>
</template>

<script>
import BDMPartImage from '../components/BDMPartImage.vue'
import BDMPartInfo from '../components/BDMPartInfo.vue'

export default {
	name: 'BDMPart',
	components: {
		BDMPartImage,
		BDMPartInfo
	},
	props: ['part', 'partUrl', 'showGoToCategoryButton'],
	methods: {
		getImageSrc() {
			return "/images/parts/" + this.part["image"]["path"];
		},
		getCallouts() {
			return this.part["image"]["callouts"];
		},
		calloutClicked(calloutLabel) {
			this.$refs["partInfo"].scrollToItem(calloutLabel);
		}
	}
}
</script>

<style scoped>
.part-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 66vh;
	margin-top: 50px;
	border: 2px solid black;
}

.part-image-container {
	flex-grow: 6;
	display: flex;
	width: 60%;
	justify-content: center;
	border-right: solid 5px black;
}

.part-info-container {
	flex-grow: 4;
	display: flex;
	flex-direction: column;
	width: 40%;
	justify-content: center;
}

.part-item-container {
	border: solid 1px green;
}

.go-to-part-button-container {}
</style>
