import { createRouter, createWebHistory } from 'vue-router'
import TractorsView from '../views/TractorsView.vue'
import CategoriesView from '../views/CategoriesView.vue'
import PartsView from '../views/PartsView.vue'
import SearchResultsView from '../views/SearchResultsView.vue'


const routes = [
	{
		path: '/',
		name: 'home',
		component: TractorsView
	},
	{
		path: '/tractors/:tractorId/categories/:categoryId/parts/:partId',
		name: 'parts2',
		component: PartsView
	},
	{
		path: '/tractors/:tractorId/categories/:categoryId',
		name: 'parts1',
		component: PartsView
	},
	{
		path: '/tractors/:tractorId/search',
		name: 'searchresults',
		component: SearchResultsView
	},
	{
		path: '/tractors/:tractorId',
		name: 'categories',
		component: CategoriesView
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

export default router
