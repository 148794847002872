<template>
	<div class="main-container" ref="mainContainer">
		<BDMNavbar :itemCount="numberOfParts" @searchTextChanged="searchTextChanged" />
		<!-- TODO: use padding/margin instead of brs -->
		<br />
		<br />
		<br />
		<br />
		<h1>{{ tractorName }} (id: {{ tractorId }})</h1>
        <h3> - {{ categoryName }} - </h3>
		<BDMPartList :parts="getAllParts" :partId="selectedPartId" :showGoToCategoryButton="aaa" />
		<br />
	</div>
</template>

<script>
import BDMNavbar from '../components/BDMNavbar.vue'
import BDMPartList from '../components/BDMPartList.vue';

export default {
	name: 'PartsView',
	data() {
		return {
			allParts: [],
			tractorId: undefined,
			tractorName: "",
			categoryName: "",
			selectedPartId: undefined,
			searchPattern: "",
			aaa: false
		};
	},
	components: {
		BDMNavbar,
		BDMPartList
	},
	mounted() {
		// retrieve parts
		const url = "/api/tractors/tractor_" + this.$route.params.tractorId + ".json";
		fetch(url)
			.then((response) => {
				if (response.ok) {
					return response.json();
				}
			})
			.then((responseJson) => {
				this.tractorDataJson = responseJson;
				this.tractorId = responseJson['tractor_id'];
				this.tractorName = responseJson['name'];
				const category = this.tractorDataJson['categories'].find(element => element["category_id"] == this.$route.params.categoryId);
				if (!category) {
					console.log("Chosen category not found!!!");
					return;
				}
				this.categoryName = category['name'];
				this.allParts = category['parts'];
				this.selectedPartId = this.$route.params.partId;
			})
			.catch((e) => {
				console.log("error: " + e);
			});
	},
	methods: {
		async searchTextChanged(searchText) {
			this.stopObservations();
			isPartContainText("a", "b");
			scroll({ top: 0, left: 0, behavior: "instant" });
			this.searchPattern = searchText;
			this.page = 0;
		},
	},
	computed: {
		getAllParts() {
			return this.allParts;
		},
		numberOfParts() {
			return this.allParts.length;
		}
	}
}

function isPartContainText(part, searchPattern) {
	if (part["name"].toLowerCase().indexOf(searchPattern) != -1 || part["code"].toLowerCase().indexOf(searchPattern) != -1) {
		return true;
	}

	for (const item of part["items"]) {
		if (item["name"].toLowerCase().indexOf(searchPattern) != -1 || item["partNumber"].toLowerCase().indexOf(searchPattern) != -1) {
			return true;
		}
	}

	return false;
}
</script>

<style>
.main-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.parts-container {
	width: 90%;
}
</style>
