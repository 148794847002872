// TODO: vue proxies and private fields
// unfortunately vue wraps these object as proxies (when used as data members)
// after which private fields cannot be accessed
// so i made all fields private
export default class Scroller {
    constructor(threshold) {
        this.intersectionObserver = new IntersectionObserver(
			(entries) => {this.intersectionObserverCallback(entries)},
			{
				root: null,
				rootMargin: "0px",
				threshold: threshold
			}
		);
        this.resolve = undefined;
        this.reject = undefined;
        this.observedElement = undefined;
    }

    async scrollIntoView(element) {
        if (this.reject != undefined) {
            this.reject();
            this.intersectionObserver.disconnect();
        }

        this.intersectionObserver.observe(element);
        this.observedElement = element;
        element.scrollIntoView({ behavior: "auto", block: "center", inline: "center"});

        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }

    intersectionObserverCallback(entries) {
        for (const entry of entries) {
            if (!entry.isIntersecting) {
                continue;
            }

            if (entry.target == this.observedElement) {
                this.resolve();
                this.intersectionObserver.disconnect();
            }
        }
    }

    intersectionObserver;
    observedElement;
    resolve;
    reject;
}