<template>
	<div>
		<BDMNavbar :itemCount="0" />
		<br />
		<br />
		<br />
		<br />
		<div class="tractor-list-container">
			<BDMTractorCard v-for="tractor in tractors" :key="tractor" :name="tractor.name"
				:description="tractor.description" :imageUrl="tractor.imageUrl" :url="tractor.url" />
		</div>
	</div>
</template>

<script>
import BDMNavbar from '../components/BDMNavbar.vue'
import BDMTractorCard from '../components/BDMTractorCard.vue'

export default {
	name: 'TractorsView',
	components: {
		BDMNavbar,
		BDMTractorCard
	},
	data() {
		return {
			tractors: []
		}
	},
	mounted() {
		const url = "/api/tractors.json";
		fetch(url)
			.then((response) => {
				if (response.ok) {
					return response.json();
				}
			})
			.then((responseJson) => {
				this.tractors = responseJson;
			})
			.catch((e) => {
				console.log("/api/tractors.json error: " + e);
			});
	}
}
</script>

<style scoped>
.tractor-list-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
</style>
