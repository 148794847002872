<template>
	<div>
		<router-view />
	</div>
</template>

<script>

export default {
	name: 'App',
	data() {
		return {
			partInfo: [],
			searchPattern: "",
			page: 0,
			itemsPerPage: 5,
			itemCount: 0,
			intersectionObserver: null
		};
	}
}
</script>

<style>
html {
	overflow: scroll;
	overflow-x: hidden;
}

html::-webkit-scrollbar {
	width: 0;
	/* Remove scrollbar space */
	background: transparent;
	/* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
html::-webkit-scrollbar-thumb {
	background: #FF0000;
}
</style>
