<template>
    <div>
        <div class="card" @click="$router.push(url)">
            <div class="container">
                <h4><b>{{ name }}</b></h4>
                <ul>
                    <li v-for="part in firstFewParts" :key="part">
                        {{part['name']}}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BDMCategoryCard',
    props: ['name', 'parts', 'url'],
    computed: {
        firstFewParts() {
            let result = [];
            for (let i = 0; i < Math.min(this.parts.length, 10); i++) {
                result.push(this.parts[i]);
            }
            return result;
        }
    }
}
</script>

<style scoped>
.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 40%;
    border-radius: 5px;
    width: 400px;
    height: 400px;
    cursor: pointer;
    overflow-y: hidden;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
