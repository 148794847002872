<template>
    <div>
        <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
            <div class="container-fluid">
                <span class="navbar-brand">Parts catalog</span>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="search-container">
                    <input type="text" v-model="searchPattern" @change="goToSearchUrl()" />
                    <span>Total {{itemCount}} items.</span>
                </div>
                <span>Milan</span>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'BDMNavbar',
    props: ['itemCount'],
    data() {
        return {
            searchPattern: ""
        }
    },
    mounted() {
        if (this.$route.query.q) {
            this.searchPattern = this.$route.query.q;
        }
    },
    methods: {
        goToSearchUrl() {
            const tractorId = this.$route.params.tractorId;
            if (this.searchPattern) {
                const url = "/tractors/" + tractorId + "/search";
                this.$router.push({path: url, query: {q: this.searchPattern}});
            } else {
                this.$router.push("/tractors/" + tractorId);
            }
        }
    }
}
</script>

<style scoped>
span {
    color: green;
}
</style>
