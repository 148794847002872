<template>
    <div>
        <div class="part-info-container">
            <a class="btn" :href="url" target="_blank">Go to website</a>
            <a v-if="showGoToCategoryButton" class="btn" :href="partUrl">Go to category</a>
            <h3>{{ name }} ({{ code }})</h3>
            <input type="text" v-model="searchPattern" @change="$emit('searchTextChanged', searchPattern)" />
            <div class="part-list-container" ref="items-container">
                <div v-for="item in filteredItems" :key="item" ref="items">
                    <h3> [{{ item["calloutLabel"] }}] </h3>
                    <ul>
                        <li>
                            Name: <strong>{{ item["name"] }}</strong>
                        </li>
                        <li>
                            Part number: <strong>{{ item["displayPartNumber"] }}</strong>
                        </li>
                        <li>
                            Quantity required: {{ item["quantityRequired"] }}
                        </li>
                        <li>
                            Remarks: {{ item["remarks"] }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BDMPartInfo',
    props: ['id', 'name', 'code', 'url', 'items', 'partUrl', 'showGoToCategoryButton'],
    data() {
        return {
            searchPattern: ""
        };
    },
    methods: {
        calloutLabelSelected(calloutLabel) {
            this.scrollIntoView(calloutLabel);
        },
        scrollToItem(calloutLabel) {
            for (let i = 0; i < this.filteredItems.length; i++) {
                if (this.filteredItems[i].calloutLabel == calloutLabel) {
                    this.$refs["items-container"].children[i].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
                    return;
                }
            }
        },
    },
    computed: {
        filteredItems() {
            const searchPattern = this.searchPattern.toLowerCase();
            let result = [];
            for (const item of this.items) {
                if (searchPattern == "" || item["name"].toLowerCase().indexOf(searchPattern) != -1 || item["displayPartNumber"].toLowerCase().indexOf(searchPattern) != -1) {
                    result.push(item);
                }
            }
            return result;
        }
    }
}
</script>

<style scoped>
.part-info-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 66vh;
}

.part-list-container {
    overflow-y: scroll;
}

.item-container {
    border: solid 1px green;
}
</style>
