<template>
	<!-- unfortunately vue $refs are not ordered the same way those elements are rendered
			so we use this extra div to access the Nth element -->
	<div class="parts-container" ref="partsContainer">
		<!-- this extra div is required for intersection observer, they dont work on vue components -->
		<div ref="partElements" v-for="part in partsToShow" :key="part">
			<BDMPart :part="part" :showGoToCategoryButton="showGoToCategoryButton" :partUrl="getPartUrl(part)" />
		</div>
	</div>
</template>

<script>
import BDMPart from '../components/BDMPart.vue'
import Scroller from '../scroll_element_into_view.js'

export default {
	name: 'BDMPartList',
	props: {
		parts: {
			type: Array
		},
		showGoToCategoryButton: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			allParts: [],
			partsToShow: [],
			partIndexFrom: 0,
			partIndexTo: 0,
			numberOfPartsToShow: 9, // should be an odd number
			intersectionObserver: null,
			// scroll to item (used when page is loaded)
			isScrollToItemNeeded: false,
			scroller: new Scroller(0.1)
		};
	},
	components: {
		BDMPart
	},
	mounted() {
		this.intersectionObserver = new IntersectionObserver(
			(entries) => { this.intersectionObserverCallback(entries) },
			{
				root: null, //this.$refs["mainContainer"],
				rootMargin: "0px",
				threshold: 0.2
			}
		);
	},
	beforeUnmount() {
		if (this.intersectionObserver) {
			this.intersectionObserver.disconnect();
		}
	},
	methods: {
		getPartUrl(part) {
			return "/tractors/" + this.$route.params.tractorId + "/categories/" + part["category_id"] + "/parts/" + part["id"];
		},
		update(indexOfPart, isScrollToElementNeeded = false) {
			this.indexOfCenterPart = indexOfPart;
			// calculate window of visible items
			this.partIndexFrom = Math.max(0, indexOfPart - Math.floor(this.numberOfPartsToShow / 2));
			this.partIndexTo = Math.min(this.partIndexFrom + this.numberOfPartsToShow - 1, this.allParts.length - 1);
			console.log("Parts shown from " + this.partIndexFrom + " to " + this.partIndexTo);

			// update part list on UI
			let partsToShow = [];
			for (let i = this.partIndexFrom; i <= this.partIndexTo; i++) {
				partsToShow.push(this.allParts[i]);
			}
			this.partsToShow = partsToShow;

			// wait for elements to be rendered
			this.$nextTick(function () {
				// this scroll is needed only once at page load, when a certain item is to be centered
				if (isScrollToElementNeeded) {
					const chosenElementIndex = indexOfPart - this.partIndexFrom;
					const element = this.$refs.partsContainer.children[chosenElementIndex];
					this.scroller.scrollIntoView(element).then(() => { console.log("in view"); this.observeKeyElements(); });
				} else {
					this.observeKeyElements();
				}
			});
		},
		intersectionObserverCallback(entries) {
			for (const entry of entries) {
				if (!entry.isIntersecting) {
					continue;
				}

				// first element of list
				if (entry.target == this.$refs.partsContainer.children[0]) {
					this.update(this.partIndexFrom);
					// last element of list
				} else if (entry.target == this.$refs.partsContainer.children[this.$refs['partElements'].length - 1]) {
					this.update(this.partIndexTo);
				}
			}
		},
		stopObservations() {
			this.intersectionObserver.disconnect();
		},
		observeKeyElements() {
			// observe last element (if exists)
			this.$nextTick(() => {
				if (0 < this.$refs['partElements'].length) {
					// visible items: [partIndexFrom -> partIndexTo]
					// unfortunately vue $refs are not ordered the same way that those elements are rendered
					// so we use an extra div to access the Nth element
					if (0 < this.partIndexFrom) {
						const elementToObserveFirst = this.$refs.partsContainer.children[0];
						this.intersectionObserver.observe(elementToObserveFirst);
					}

					if (this.partIndexTo < this.allParts.length - 1) {
						const elementToObserveLast = this.$refs.partsContainer.children[this.$refs['partElements'].length - 1];
						this.intersectionObserver.observe(elementToObserveLast);
					}
				}
			});
		},
	},
	watch: {
		parts: function () {
			this.allParts = this.parts;

			// calculate index of element centered at screen
			if (this.$route.params.partId) {
				this.isScrollToItemNeeded = true;
				const index = this.allParts.findIndex(element => element["id"] == this.$route.params.partId);
				if (index == -1) {
					console.log("Part with id " + this.$route.params.partId + " not found");
					this.update(0);
				} else {
					this.update(index, true);
				}
			} else {
				this.update(0);
			}
		}
	}
}
</script>

<style>
.main-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.parts-container {
	width: 90%;
}
</style>
